<template>
    <div class="venn-diagram-bank">
        <span>{{$t('bank')}}</span>
        <div class="bank-container">
            <draggable class="draggable-section" v-model="elements" :options="dragOptions"
                :move="onMove" @change="onChange">
                <transition-group name="flip-list">
                    <VennDiagramSetElement v-for="(element, index) in elements" :key="index"
                        :element="element" :question="question"/>   
                </transition-group>
            </draggable>
        </div>
    </div>
</template>
<script>
import VennDiagramDraggableMixin from '@/components/assessment/VennDiagramDraggableMixin.js'
export default {
    name: 'VennDiagramBank',
    mixins:[VennDiagramDraggableMixin], 
}
</script>
<style lang="less" scoped>
.bank-container .venn-diagram-set-element.failed{
    background-color: #FFF;
}
.venn-diagram-bank{
    // margin-top: -80px;
    >span{
        margin-left: 16px;
    }
    .bank-container{
        // width: 720px;
        min-height: 50px;
        margin: auto;
        margin-top: 2px;
        padding: 8px;
        box-sizing: border-box;
        background-color: #E8E8E8;
        .draggable-section{
            min-height: unset;
            span{
                // min-height: 100px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 8px;
            }
        }
    }
}
</style>

<template>
    <div class="venn-diagram-set column">
        <span>{{options.name}}</span>
        <div class="diagram-set column" :class="options.set">
            <draggable class="draggable-section"  v-model="elements" :options="dragOptions"
                @start="isDragging=true" @end="isDragging=false"
                :move="onMove" @change="onChange">
                <transition-group name="flip-list">
                    <VennDiagramSetElement v-for="(element, index) in elements"
                        :key="index"  :element="element" :isFeedback="isFeedback" :question="question"/>  
                </transition-group>
            </draggable>
        </div>
    </div>
</template>
<script>
import VennDiagramDraggableMixin from '@/components/assessment/VennDiagramDraggableMixin.js'
export default {
    name: 'VennDiagramSet',
    mixins:[VennDiagramDraggableMixin], 
}
</script>
<style lang="less" scoped>
.venn-diagram-set{
    margin-top: 16px;
    >span{
        margin: auto;
        text-align: center;
    }
    .diagram-set{
        width: 280px;
        // height: 400px;
        position:relative;

        .draggable-section{
            margin: auto;
            height: auto;
            display: flex;
            flex-direction: column;
            z-index: 2;
            
            span{
                margin-inline: auto;
                margin-top: 6rem;
                min-width: 150px;;
                min-height: 75px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;

                max-height: 400px;
                overflow-y: auto;

                &::-webkit-scrollbar{
                    display: none;
                }
            }
        }

        &.primary-set::before, &.secondary-set::before{
            content:'';
            position:absolute;
            // margin-left: -60px;
            top: 0;
            left: 0;
            width: 600px;
            height: 250px;
            background-color: rgba(88,88,88,0.2);
            border-radius: 50%;
        }
        &.secondary-set::before{ 
            transform: translate(-50%);
        }
    }
}
</style>



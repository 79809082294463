export default{
    methods: {
        getScore(questions){
            let score= 0;
            questions = this.setIndividualScore(questions).filter(i => i.score != undefined)
            questions.forEach(e => score += e.score)
            score = score / (questions.length == 0? 1: questions.length)
            return score !== Math.floor(score) ? score.toFixed(2) : score
        }, 
        getCorrectAnswers(questions){
            questions = this.setIndividualScore(questions)
            return questions.filter(e => e.score >= 70).length
        }, 
        setIndividualScore(questions){
            questions
                .filter(e => e.type=== 'multiple-choice')
                .map(e => {
                    e.score = e.choices.length == 0
                        ? undefined
                        :e.score = e.isCorrect ? 100 : 0
                })
            questions
                .filter(e => e.type=== 'venn-diagram')
                .map(e => {
                    e.score = e.vennDiagramElements.length == 0
                        ? undefined
                        : e.vennDiagramElements
                            .filter(i => i.studentAnswerSet === i.correctAnswerSet)
                            .length  * 100 / e.vennDiagramElements.length
                })
            return questions
        }
    },
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "venn-diagram-set column" }, [
    _c("span", [_vm._v(_vm._s(_vm.options.name))]),
    _c(
      "div",
      { staticClass: "diagram-set column", class: _vm.options.set },
      [
        _c(
          "draggable",
          {
            staticClass: "draggable-section",
            attrs: { options: _vm.dragOptions, move: _vm.onMove },
            on: {
              start: function($event) {
                _vm.isDragging = true
              },
              end: function($event) {
                _vm.isDragging = false
              },
              change: _vm.onChange
            },
            model: {
              value: _vm.elements,
              callback: function($$v) {
                _vm.elements = $$v
              },
              expression: "elements"
            }
          },
          [
            _c(
              "transition-group",
              { attrs: { name: "flip-list" } },
              _vm._l(_vm.elements, function(element, index) {
                return _c("VennDiagramSetElement", {
                  key: index,
                  attrs: {
                    element: element,
                    isFeedback: _vm.isFeedback,
                    question: _vm.question
                  }
                })
              })
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
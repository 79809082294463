var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "venn-diagram-set-element row",
      class: _vm.elementBackGroundStatus
    },
    [
      _c("span", [
        _vm._v("\n        " + _vm._s(_vm.element.content) + "\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import draggable from 'vuedraggable'
import VennDiagramSetElement from '@/components/assessment/VennDiagramSetElement.vue'
export default{
    components:{
        draggable, 
        VennDiagramSetElement
    },
    props:{
        options: Object, 
        question: Object,
        isFeedback: Boolean
    },
    data(){
        return{
            isDragging: false,
            delayedDragging: false
        }
    },
    methods:{        
        onMove({relatedContext, draggedContext}) {
            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;
            return (
                (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            );
        }, 
        onChange({added}){
            if(this.$store.state.isPreviewModeActive 
                || this.isAssessmentSubmitted)
                return

                // if(this.checkAssessmentType === 'GP'){
                //     // console.log("sadj")
                   
                //     return
                // }
                
            if(added){
                this.$store.dispatch('setVennDiagramStudentAnswer',{
                    assessmentId: this.$store.state.assessment.id,
                    questionId: this.question.id,
                    venDiagramSet: this.options.set,
                    venDiagramElementId: added.element.id
                })
                .then(()=>{
                    let element = this.question.vennDiagramElements
                        .find(e => e.id === added.element.id)
                    this.$store.commit('updateVennDiagramElement', {
                        element: element, 
                        set: this.options.set
                    })
                })
                // this.$store.commit('sendStatusToFooter',{questionId:this.question.id})
                let studentAnswer ={
                    questionId: this.question.id,
                    venDiagramSet: this.options.set,
                    venDiagramElementId: added.element.id
                }
                this.$root.$emit('studentvennanswer',studentAnswer)
            }
        }
    },
    computed: {
        dragOptions(){    
            return {
                animation: 0,
                group: "elements",
                ghostClass: "ghost"
            }
        }, 
        elements:{
            get(){
                return this.question.vennDiagramElements
                    .filter(e => e.studentAnswerSet === this.options.set)
            }, 
            set(){
            }
        }, 
        isAssessmentSubmitted(){
            // return this.$store.state.assessment.assessmentCompleted || this.isFeedback;
            // return this.question.isSubmitted 
            // if(this.question.groupHeaderType === 1 || this.question.isSubmitted){
            //     return false
            // }
            // else if(this.question.isSubmitted && this.question.groupHeaderType === 2){
            //     return true
            // }
            // else{
            //     return false
            // }
            return this.question.isSubmitted
        },
        // checkAssessmentType(){
        //     return this.question.groupHeaderType == 1 ? 'GP': this.question.groupHeaderType == 2 ? 'IP' : 'NO'
        // }
    },
    watch: {
      isDragging(newValue) {
        if (newValue) {
          this.delayedDragging = true;
          return;
        }
        this.$nextTick(() => {
          this.delayedDragging = false;
        });
      }
    }
}
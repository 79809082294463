<template>
  <div
    class="venn-diagram-section column mb-5"
    :style="{ order: content.order }"
    :id="'cmp' + content.id"
  >
    <div class="instruction-outer" v-if="content.instruction">
      <AudioToggle
        class="pl-1 primary-audio"
        v-if="content.instructionAudio"
        :audio="content.instructionAudio"
      />
      <div class="ml-4" v-html="content.instruction"></div>
    </div>
    <div class="diagram-directions column">
      <div class="row d-f__center venn-primary_source">
        <AudioToggle
          class="ml-4 mt-3"
          v-if="content.primarySourceAudio"
          :audio="content.primarySourceAudio"
        />
        <span
          id="question"
          class="ml-4 mt-3 pr-3 question"
          v-html="content.primaryTextSource"
        >
        </span>
      </div>
      <div class="row mb-3 mt-3">
        <span>{{ $t("compareTag") }}</span>
      </div>
      <div class="row sets venn-images_compare">
        <div class="set column">
          <img
            class="set-image"
            v-if="content.primarySetImage"
            :src="getImageUrl(content.primarySetImage.name)"
          />
          <span style="margin-block-start: 4px">{{
            content.primarySetContent
          }}</span>
        </div>

        <div class="venn-diagram-question_and_audio">
          <AudioToggle
            class="mr-2"
            v-if="content.audio"
            :audio="content.audio"
          />
          <p class="custom-scrollbar pr-1" v-html="content.question"></p>
        </div>
        <div class="set column">
          <img
            class="set-image"
            v-if="content.secondarySetImage"
            :src="getImageUrl(content.secondarySetImage.name)"
          />
          <span style="margin-block-start: 4px">{{
            content.secondarySetContent
          }}</span>
        </div>
      </div>
    </div>
    <div class="sets-container row">
      <VennDiagramSet
        :question="content"
        :options="{ set: 'primary-set', name: content.primarySetContent }"
        :isFeedback="feedbackQuestion != null"
      />
      <VennDiagramSet
        :question="content"
        :options="{ set: 'intersection', name: this.$t('both') }"
        :isFeedback="feedbackQuestion != null"
      />
      <VennDiagramSet
        :question="content"
        :options="{ set: 'secondary-set', name: content.secondarySetContent }"
        :isFeedback="feedbackQuestion != null"
      />
    </div>
    <VennDiagramBank
      v-if="!feedbackQuestion"
      :question="content"
      :options="{ set: 'empty' }"
    />
    <div class="outer-mcq-submit">
      <button
        class="btn round primary back"
        @click="submitAssessmetGroup"
        :disabled="content.isSubmitted || isPreviewMode"
        :class="content.isSubmitted ? 'secondary cursor-none' : 'primary'"
      >
        {{ $t("submit") }}
      </button>
    </div>
    <modal
      v-if="showModal"
      :click-handler="completeAssessment"
      text-button="OK"
      @close="showModal = false"
    >
      <div class="message">
        <div>{{ submitMessage }}</div>
      </div>
    </modal>
  </div>
</template>
<script>
import VennDiagramBank from "@/components/assessment/VennDiagramBank.vue";
import VennDiagramSet from "@/components/assessment/VennDiagramSet.vue";
import AudioToggle from "@/common/AudioToggle.vue";
import Modal from "@/common/Modal.vue";
export default {
  components: {
    VennDiagramBank,
    VennDiagramSet,
    AudioToggle,
    Modal,
  },
  props: {
    feedbackQuestion: {
      type: Object,
      required: false,
      default: null,
    },
    content: {
      type: Object,
    },
  },
  data() {
    return {
      showModal: false,
      submitMessage: "",
      studentAnswerObj: null,
    };
  },
  computed: {
    isPreviewMode() {
      return this.$store.state.isPreviewModeActive;
    },
    // question(){
    //     if(this.feedbackQuestion)
    //         return this.feedbackQuestion.vennDiagram;

    //     return this.$store.state.assessment
    //         .questions.find(e => e.type==='venn-diagram');
    // }
  },
  methods: {
    addElement() {
      this.$store.commit("addVennDiagramElement");
    },
    submitAssessmetGroup() {
      this.showModal = true;
      this.submitMessage = this.$t("submitAssessmentMessage");
    },
    completeAssessment() {
      this.showModal = false;
      // this.isLoadingInformation = true
      this.$store.commit("loadCurrentQuestion", this.content);
      this.$store.dispatch("submitVennDiagramQuestion", this.studentAnswerObj);
      this.$store.commit("submitIndividualQuestion");
    },
  },
  mounted() {
    this.$root.$on("studentvennanswer", (studentAnswer) => {
      this.studentAnswerObj = studentAnswer;
    });
  },
};
</script>
<style lang="less" scoped>
.instruction-outer {
  padding: 20px;
  padding-left: 18px !important;
  background: white;
  border: 1px solid #ededed;
  display: flex;
}
.outer-mcq-submit {
  padding: 10px;
  text-align: center;
}
.venn-diagram-section {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0px auto;
  color: #0a1554;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  background: rgb(245, 245, 245, 0.1);

  .diagram-directions {
    padding: 16px;
    padding-top: 0 !important;
    box-sizing: border-box;
    background-color: #e0e0e0;

    span {
      margin: auto;
    }

    .sets {
      // margin-top: 24px;
      p {
        max-width: 680px;
        max-height: 200px;
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.5px;
        line-height: 28px;
      }
      .column {
        min-width: 147px;
        max-width: 147px;
        min-height: 147px;
      }
      // .column:first-of-type{
      //     margin-left: 64px;
      // }
      // span{
      //     margin: auto;
      // }
      // .set:nth-child(3){
      //     margin-left: auto;
      //     margin-right: 41px;
      // }
    }
    .set-image {
      max-height: 200px;
      max-width: 200px;
      height: auto;
      width: auto;
    }
  }

  .sets-container {
    justify-content: center;
  }
}
.d-f__center {
  display: flex;
  // align-items: center;
}

.venn-diagram-question_and_audio {
  display: flex;
  max-width: 624px;
}
.compare-tag {
  // margin-left: 20rem !important;
  font-style: normal !important;
  font-family: "Roboto", sans-serif !important;
  color: #0a1554 !important;
  font-weight: 600 !important;
  margin-top: 32px !important;
}
.venn-primary_source {
  background-color: #f0f0f0 !important;
  margin-top: -1px;
  margin-left: -17px;
  margin-right: -17px;
}
.venn-images_compare {
  display: flex;
  justify-content: space-between;
  padding-inline: 72px;
}
</style>


var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "venn-diagram-bank" }, [
    _c("span", [_vm._v(_vm._s(_vm.$t("bank")))]),
    _c(
      "div",
      { staticClass: "bank-container" },
      [
        _c(
          "draggable",
          {
            staticClass: "draggable-section",
            attrs: { options: _vm.dragOptions, move: _vm.onMove },
            on: { change: _vm.onChange },
            model: {
              value: _vm.elements,
              callback: function($$v) {
                _vm.elements = $$v
              },
              expression: "elements"
            }
          },
          [
            _c(
              "transition-group",
              { attrs: { name: "flip-list" } },
              _vm._l(_vm.elements, function(element, index) {
                return _c("VennDiagramSetElement", {
                  key: index,
                  attrs: { element: element, question: _vm.question }
                })
              })
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
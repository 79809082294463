var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "venn-diagram-section column mb-5",
      style: { order: _vm.content.order },
      attrs: { id: "cmp" + _vm.content.id }
    },
    [
      _vm.content.instruction
        ? _c(
            "div",
            { staticClass: "instruction-outer" },
            [
              _vm.content.instructionAudio
                ? _c("AudioToggle", {
                    staticClass: "pl-1 primary-audio",
                    attrs: { audio: _vm.content.instructionAudio }
                  })
                : _vm._e(),
              _c("div", {
                staticClass: "ml-4",
                domProps: { innerHTML: _vm._s(_vm.content.instruction) }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "diagram-directions column" }, [
        _c(
          "div",
          { staticClass: "row d-f__center venn-primary_source" },
          [
            _vm.content.primarySourceAudio
              ? _c("AudioToggle", {
                  staticClass: "ml-4 mt-3",
                  attrs: { audio: _vm.content.primarySourceAudio }
                })
              : _vm._e(),
            _c("span", {
              staticClass: "ml-4 mt-3 pr-3 question",
              attrs: { id: "question" },
              domProps: { innerHTML: _vm._s(_vm.content.primaryTextSource) }
            })
          ],
          1
        ),
        _c("div", { staticClass: "row mb-3 mt-3" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("compareTag")))])
        ]),
        _c("div", { staticClass: "row sets venn-images_compare" }, [
          _c("div", { staticClass: "set column" }, [
            _vm.content.primarySetImage
              ? _c("img", {
                  staticClass: "set-image",
                  attrs: {
                    src: _vm.getImageUrl(_vm.content.primarySetImage.name)
                  }
                })
              : _vm._e(),
            _c("span", { staticStyle: { "margin-block-start": "4px" } }, [
              _vm._v(_vm._s(_vm.content.primarySetContent))
            ])
          ]),
          _c(
            "div",
            { staticClass: "venn-diagram-question_and_audio" },
            [
              _vm.content.audio
                ? _c("AudioToggle", {
                    staticClass: "mr-2",
                    attrs: { audio: _vm.content.audio }
                  })
                : _vm._e(),
              _c("p", {
                staticClass: "custom-scrollbar pr-1",
                domProps: { innerHTML: _vm._s(_vm.content.question) }
              })
            ],
            1
          ),
          _c("div", { staticClass: "set column" }, [
            _vm.content.secondarySetImage
              ? _c("img", {
                  staticClass: "set-image",
                  attrs: {
                    src: _vm.getImageUrl(_vm.content.secondarySetImage.name)
                  }
                })
              : _vm._e(),
            _c("span", { staticStyle: { "margin-block-start": "4px" } }, [
              _vm._v(_vm._s(_vm.content.secondarySetContent))
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "sets-container row" },
        [
          _c("VennDiagramSet", {
            attrs: {
              question: _vm.content,
              options: {
                set: "primary-set",
                name: _vm.content.primarySetContent
              },
              isFeedback: _vm.feedbackQuestion != null
            }
          }),
          _c("VennDiagramSet", {
            attrs: {
              question: _vm.content,
              options: { set: "intersection", name: this.$t("both") },
              isFeedback: _vm.feedbackQuestion != null
            }
          }),
          _c("VennDiagramSet", {
            attrs: {
              question: _vm.content,
              options: {
                set: "secondary-set",
                name: _vm.content.secondarySetContent
              },
              isFeedback: _vm.feedbackQuestion != null
            }
          })
        ],
        1
      ),
      !_vm.feedbackQuestion
        ? _c("VennDiagramBank", {
            attrs: { question: _vm.content, options: { set: "empty" } }
          })
        : _vm._e(),
      _c("div", { staticClass: "outer-mcq-submit" }, [
        _c(
          "button",
          {
            staticClass: "btn round primary back",
            class: _vm.content.isSubmitted
              ? "secondary cursor-none"
              : "primary",
            attrs: { disabled: _vm.content.isSubmitted || _vm.isPreviewMode },
            on: { click: _vm.submitAssessmetGroup }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("submit")) + "\n    ")]
        )
      ]),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.completeAssessment,
                "text-button": "OK"
              },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("div", { staticClass: "message" }, [
                _c("div", [_vm._v(_vm._s(_vm.submitMessage))])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }